
import { Component, Vue } from 'vue-property-decorator'
import { ElForm } from 'element-ui/types/form'

@Component
export default class extends Vue {
  private dateRange: any = []

  get projectList () {
    return this.$store.state.projectList
  }

  pickerOptions = {}

  private planInfo: any = {
    projectId: '',
    planStart: '',
    planEnd: '',
    userIds: '',
    taskList: []
  }

  private rules = {
    projectId: [{ required: true, message: '请选择项目', trigger: 'change' }],
    planStart: [{ required: true, message: '请选择计划日期' }],
    userIds: [{ required: true, message: '请选择任务抄送人' }],
    taskList: [{ required: true, message: '请填写作业任务' }],
    taskNotes: [{ required: true, message: '请填写作业任务' }]
  }

  private userList = []
  private unFinisedList = []
  private submitShow = false

  get planId () {
    return this.$route.params.planId
  }

  created () {
    // 1
    this.onAdd()
    if (this.planId) {
      this.getDetail()
    }
  }

  onAdd () {
    this.planInfo.taskList.push({
      taskNotes: ''
    })
  }

  onClose (index: number) {
    this.planInfo.taskList.splice(index, 1)
  }

  getDetail () {
    this.$axios.get(this.$apis.operationmanage.selectYhDailyPlanByPlanId, {
      planId: this.planId
    }).then(async res => {
      if (this.planId) {
        this.planInfo.projectId = res.projectId
        this.dateRange = [res.planStart, res.planEnd]
        await this.getProjectUserList()
        this.planInfo = res || {}
        this.planInfo.userIds = res.userIds.split(',')
        ;(this.$refs.planInfo as ElForm).clearValidate()
      }
    })
  }

  change () {
    this.getProjectUserList()
    this.getLastPlan()
  }

  getLastPlan () {
    this.$axios.get(this.$apis.operationmanage.selectUpYhDailyPlanByProjectId, {
      projectId: this.planInfo.projectId
    }).then(res => {
      if (res) {
        this.unFinisedList = res.taskList.filter((t: any) => t.taskState === '0')
        this.dateRange = [this.$dayjs(res.planEnd).add(1, 'day').format('YYYY-MM-DD'), this.$dayjs(res.planEnd).add(8, 'day').format('YYYY-MM-DD')]
      } else {
        this.unFinisedList = []
        this.dateRange = [this.$dayjs().format('YYYY-MM-DD'), this.$dayjs().add(7, 'day').format('YYYY-MM-DD')]
      }
      this.pickerOptions = {
        disabledDate: (time: Date) => {
          const date = res && res.planEnd ? this.$dayjs(res.planEnd).format('YYYY/MM/DD') : this.$dayjs().format('YYYY/MM/DD')
          const current = this.$dayjs(date).valueOf()
          return time.getTime() < current
        }
      }
    })
  }

  add (item: any, index: number) {
    this.unFinisedList.splice(index, 1)
    if (!this.planInfo.taskList[this.planInfo.taskList.length - 1].taskNotes) {
      this.planInfo.taskList[this.planInfo.taskList.length - 1].taskNotes = item
      this.planInfo.taskList.push({
        taskNotes: ''
      })
    } else {
      this.planInfo.taskList.push({
        taskNotes: item
      })
    }
  }

  // 项目人员列表
  getProjectUserList () {
    this.$axios.get(this.$apis.project.selectYhProjectUserByList, {
      projectId: this.planInfo.projectId
    }).then(res => {
      this.userList = res || []
    })
  }

  onSubmit () {
    if (this.dateRange.length > 0) {
      this.planInfo.planStart = this.dateRange[0]
      this.planInfo.planEnd = this.dateRange[1]
    }
    ;(this.$refs.planInfo as ElForm).validate((valid: any) => {
      if (valid) {
        this.submitShow = true
        const url = this.planId
          ? this.$apis.operationmanage.updateYhDailyPlan
          : this.$apis.operationmanage.insertYhDailyPlan
        const params = JSON.parse(JSON.stringify(this.planInfo))
        params.userIds = this.planInfo.userIds.join(',')
        params.userNames = this.planInfo.userIds.map((id: any) => {
          const obj: any = this.userList.find((o: any) => o.userId === id)
          return obj ? obj.userName : ''
        }).join(',')
        this.$axios.post(url, params).then((res) => {
          this.$message.success('保存成功')
          this.$router.back()
        }).catch(() => {
          this.submitShow = false
        })
      }
    })
  }
}
